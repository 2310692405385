export interface TemplatesSummary {
    mail: any[];
    inviteTemplateId: string;
    confirmTemplateId: string;
    refuseTemplateId: string;
    solicitTemplateId: string;
    reminderTemplateId: string;
    inviteSolicitEnabled: boolean;
    useReminderTemplate: boolean;
    inviteFirstSolicit: number;
    inviteOtherSolicit: number;
    reminderDate: Date;
    // opzioni aggiuntive comunicazioni
    enableSaveTheDate: boolean;
    enableExpirationWarning: boolean;
    getRemainderTemplateByCourseType: Function;
    getSolicitTemplateByCourseType: Function;
}

let app = angular.module("app");

app.factory("CourseCommunicationService", ["$translate", "BgacademyApplicationData", "moment", 
    ($translate: angular.translate.ITranslateService,
        BgacademyApplicationData: any,
        moment: any) => {
        return {
            getDefaultTemplatesSummary(courseType?: string): TemplatesSummary {
                let isOnlineCourse: boolean =  courseType && courseType == BgacademyApplicationData.constants.ONLINE ? true : false;

                return {
                    mail: [{ id: 1, name: $translate.instant("editCourse.NONE") }],
                    inviteTemplateId: null,
                    confirmTemplateId: null,
                    refuseTemplateId: null,
                    solicitTemplateId: isOnlineCourse ? BgacademyApplicationData.constants.INVITE_SOLICIT_ONLINE_MAIL_TEMPLATE_ID : BgacademyApplicationData.constants.INVITE_SOLICIT_MAIL_TEMPLATE_ID,
                    reminderTemplateId: isOnlineCourse ? BgacademyApplicationData.constants.REMINDER_ONLINE_MAIL_TEMPLATE_ID : BgacademyApplicationData.constants.REMINDER_MAIL_TEMPLATE_ID,
                    inviteSolicitEnabled: false,
                    useReminderTemplate: false,
                    inviteFirstSolicit: 0,
                    inviteOtherSolicit: 0,
                    reminderDate: null,
                    // opzioni aggiuntive comunicazioni
                    enableSaveTheDate: null,
                    enableExpirationWarning: null,
                    getRemainderTemplateByCourseType: (courseType: string) => {
                        let isOnlineCourse: boolean =  courseType && courseType == BgacademyApplicationData.constants.ONLINE ? true : false;
                        return isOnlineCourse ? BgacademyApplicationData.constants.REMINDER_ONLINE_MAIL_TEMPLATE_ID : BgacademyApplicationData.constants.REMINDER_MAIL_TEMPLATE_ID;
                    },
                    getSolicitTemplateByCourseType: (courseType: string) => {
                        let isOnlineCourse: boolean =  courseType && courseType == BgacademyApplicationData.constants.ONLINE ? true : false;
                        return isOnlineCourse ? BgacademyApplicationData.constants.INVITE_SOLICIT_ONLINE_MAIL_TEMPLATE_ID : BgacademyApplicationData.constants.INVITE_SOLICIT_MAIL_TEMPLATE_ID;
                    }
                }
            },
        
            getCourseDateTemplates(courseDate: any, templates?: TemplatesSummary): TemplatesSummary {
                // Pulisco i template
                let defaultTemplates: TemplatesSummary = this.getDefaultTemplatesSummary();
                if(!templates) {
                    templates = defaultTemplates;
                } else {
                    templates.inviteTemplateId = defaultTemplates.inviteTemplateId;
                    templates.confirmTemplateId = defaultTemplates.confirmTemplateId;
                    templates.refuseTemplateId = defaultTemplates.refuseTemplateId;
                    templates.reminderTemplateId = defaultTemplates.reminderTemplateId;
                    templates.useReminderTemplate = defaultTemplates.useReminderTemplate;
                    templates.reminderDate = defaultTemplates.reminderDate;
                    templates.solicitTemplateId = defaultTemplates.solicitTemplateId;
                    templates.inviteSolicitEnabled = defaultTemplates.inviteSolicitEnabled;
                    templates.inviteFirstSolicit = defaultTemplates.inviteFirstSolicit;
                    templates.inviteOtherSolicit = defaultTemplates.inviteOtherSolicit;
                    templates.enableSaveTheDate = defaultTemplates.enableSaveTheDate;
                    templates.enableExpirationWarning = defaultTemplates.enableExpirationWarning;
                }
        
                if (courseDate.courseDateAttributes.length) {
                    for (let courseDateAttribute of courseDate.courseDateAttributes) {
                        switch (courseDateAttribute.courseDateAttributeType) {
                            case BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE:
                                // Template di invito
                                if (courseDateAttribute.courseDateAttributeValue == BgacademyApplicationData.constants.COURSE_USER_INVITE_MAIL) {
                                    // Salvo l'id del template
                                    templates.inviteTemplateId = courseDateAttribute.referenceId;
                                }
                                // Template di conferma
                                if (courseDateAttribute.courseDateAttributeValue == BgacademyApplicationData.constants.CONFIRM_SUBSCRIPTION_MAIL) {
                                    // Salvo l'id del template
                                    templates.confirmTemplateId = courseDateAttribute.referenceId;
                                }
                                // Template di rifiuto/annullamento
                                if (courseDateAttribute.courseDateAttributeValue == BgacademyApplicationData.constants.REFUSE_SUBSCRIPTION_MAIL) {
                                    // Salvo l'id del template
                                    templates.refuseTemplateId = courseDateAttribute.referenceId;
                                }
                                // Template del reminder
                                if (courseDateAttribute.courseDateAttributeValue == BgacademyApplicationData.constants.REMINDER_MAIL) {
                                    // Salvo l'id del template
                                    templates.reminderTemplateId = courseDateAttribute.referenceId;
                                    // segno che è attivo 
                                    templates.useReminderTemplate = courseDate.useReminderTemplate;
                                    // e salvo la data
                                    templates.reminderDate = moment(courseDate.reminderDate).toDate();
                                }
                                // Template dei solleciti
                                if (courseDateAttribute.courseDateAttributeValue == BgacademyApplicationData.constants.INVITE_SOLICIT_MAIL) {
                                    // Salvo l'id del template
                                    templates.solicitTemplateId = courseDateAttribute.referenceId;
                                    // segno che è attivo 
                                    templates.inviteSolicitEnabled = courseDate.inviteSolicitEnabled;
                                    // salvo l'ora per il primo sollecito
                                    templates.inviteFirstSolicit = courseDate.inviteFirstSolicit;
                                    // e l'ora per gli altri solleciti
                                    templates.inviteOtherSolicit = courseDate.inviteOtherSolicit;
                                }
                                break;
                            case BgacademyApplicationData.constants.SAVE_THE_DATE:
                                templates.enableSaveTheDate = courseDateAttribute.courseDateAttributeValue === "true" ? true : false;
                                break;
                            case BgacademyApplicationData.constants.EXPIRATION_WARNING:
                                templates.enableExpirationWarning = courseDateAttribute.courseDateAttributeValue === "true" ? true : false;
                                break;
                        }
                    }
                }
        
                return templates;
            },
        
            fillCourseDateAttributesTemplates(courseDate: any, templates: TemplatesSummary, courseType?: string) {
                // Comunicazioni
                // Aggiorno la lista dei template mail
                if (courseDate.courseDateAttributes.length) {
                    for (let i = courseDate.courseDateAttributes.length - 1; i >= 0; i--) {
                        if (courseDate.courseDateAttributes[i].courseDateAttributeType == BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE
                              || courseDate.courseDateAttributes[i].courseDateAttributeType == BgacademyApplicationData.constants.SAVE_THE_DATE
                              || courseDate.courseDateAttributes[i].courseDateAttributeType == BgacademyApplicationData.constants.EXPIRATION_WARNING) {
                            courseDate.courseDateAttributes.splice(i, 1);
                        }
                    }
                }
        
                // Preparo tutti i template obbligatori aggiornati
                let inviteTemplate: any = null;
                let confirmTemplate: any = null;
                let refuseTemplate: any = null;
                let solicitTemplate: any = null;
                let reminderTemplate: any = null;
        
                // Invito
                inviteTemplate = {
                    courseDateId: courseDate.courseDateId,
                    courseDateAttributeType: BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE,
                    courseDateAttributeValue: BgacademyApplicationData.constants.COURSE_USER_INVITE_MAIL,
                    referenceApplicationName: BgacademyApplicationData.constants.BGACADEMY,
                    referenceType: BgacademyApplicationData.constants.TEXT_TEMPLATE,
                    referenceId: templates.inviteTemplateId
                }
        
                // Conferma
                confirmTemplate = {
                    courseDateId: courseDate.courseDateId,
                    courseDateAttributeType: BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE,
                    courseDateAttributeValue: BgacademyApplicationData.constants.CONFIRM_SUBSCRIPTION_MAIL,
                    referenceApplicationName: BgacademyApplicationData.constants.BGACADEMY,
                    referenceType: BgacademyApplicationData.constants.TEXT_TEMPLATE,
                    referenceId: templates.confirmTemplateId
                }
        
                // Rifiuto/annullamento
                refuseTemplate = {
                    courseDateId: courseDate.courseDateId,
                    courseDateAttributeType: BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE,
                    courseDateAttributeValue: BgacademyApplicationData.constants.REFUSE_SUBSCRIPTION_MAIL,
                    referenceApplicationName: BgacademyApplicationData.constants.BGACADEMY,
                    referenceType: BgacademyApplicationData.constants.TEXT_TEMPLATE,
                    referenceId: templates.refuseTemplateId
                }
        
                // li aggiungo all'edizione
                courseDate.courseDateAttributes.push(inviteTemplate);
                courseDate.courseDateAttributes.push(confirmTemplate);
                courseDate.courseDateAttributes.push(refuseTemplate);
        
                // Reminder
                if (templates.useReminderTemplate && templates.reminderTemplateId && templates.reminderDate) {
                    reminderTemplate = {
                        courseDateId: courseDate.courseDateId,
                        courseDateAttributeType: BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE,
                        courseDateAttributeValue: BgacademyApplicationData.constants.REMINDER_MAIL,
                        referenceApplicationName: BgacademyApplicationData.constants.BGACADEMY,
                        referenceType: BgacademyApplicationData.constants.TEXT_TEMPLATE,
                        referenceId: templates.getRemainderTemplateByCourseType(courseType)
                    }
                    courseDate.useReminderTemplate = true;
                    // Setto la data del reminder
                    courseDate.reminderDate = templates.reminderDate;
                    // E aggiungo i nuovi dati all'edizione
                    courseDate.courseDateAttributes.push(reminderTemplate);
                }
        
                // Se non è attivo il reminder, pulisco i relativi dati
                if (!templates.useReminderTemplate) {
                    courseDate.useReminderTemplate = false;
                    courseDate.reminderDate = null;
                }
        
                // Stessa cosa per i solleciti
                if (!templates.inviteSolicitEnabled) {
                    courseDate.inviteSolicitEnabled = false;
                    courseDate.inviteFirstSolicit = null;
                    courseDate.inviteOtherSolicit = null;
                }
        
                // Sollecito
                if (templates.inviteSolicitEnabled && templates.solicitTemplateId && templates.inviteFirstSolicit && templates.inviteOtherSolicit) {
                    solicitTemplate = {
                        courseDateId: courseDate.courseDateId,
                        courseDateAttributeType: BgacademyApplicationData.constants.COURSE_DATE_TEMPLATE,
                        courseDateAttributeValue: BgacademyApplicationData.constants.INVITE_SOLICIT_MAIL,
                        referenceApplicationName: BgacademyApplicationData.constants.BGACADEMY,
                        referenceType: BgacademyApplicationData.constants.TEXT_TEMPLATE,
                        referenceId: templates.getSolicitTemplateByCourseType(courseType)
                    }
                    // Segnalo che è stato attivato l'invio dei solleciti
                    courseDate.inviteSolicitEnabled = true;
                    // Setto le ore per il primo sollecito
                    courseDate.inviteFirstSolicit = templates.inviteFirstSolicit;
                    // e le ore per i successivi
                    courseDate.inviteOtherSolicit = templates.inviteOtherSolicit;
                    // E aggiungo i nuovi dati all'edizione
                    courseDate.courseDateAttributes.push(solicitTemplate);
                }
        
                // Save the date
                if (templates.enableSaveTheDate != null) {
                    let saveTheDateAttribute = {
                        courseDateId: courseDate.courseDateId,
                        courseDateAttributeType: BgacademyApplicationData.constants.SAVE_THE_DATE,
                        courseDateAttributeValue: templates.enableSaveTheDate
                    }
        
                    courseDate.courseDateAttributes.push(saveTheDateAttribute);
                }
        
                // Avviso scadenza
                if (templates.enableExpirationWarning != null) {
                    let expirationDateAttribute = {
                        courseDateId: courseDate.courseDateId,
                        courseDateAttributeType: BgacademyApplicationData.constants.EXPIRATION_WARNING,
                        courseDateAttributeValue: templates.enableExpirationWarning
                    }
        
                    courseDate.courseDateAttributes.push(expirationDateAttribute);
                }
        }
    }}]);